import React from 'react';
import { Element, PlaceholderText } from './ExchangeRates.styles';
import useExchangeRate from '@/components/exchange/hooks/useExchangeRate';

export type ExchangeRatesProps = {
  appearance?: 'horizontal' | 'vertical';
};

const ExchangeRates = ({ appearance = 'horizontal' }: ExchangeRatesProps) => {
  const { values, isLoading, error } = useExchangeRate();

  return (
    <Element appearance={appearance}>
      <div>
        {(appearance === 'horizontal' && <small>Câmbio</small>) || <small>Atualizado em</small>}
        <PlaceholderText isLoading={isLoading}>
          <strong>{values.updated_at}</strong>
        </PlaceholderText>
      </div>
      <span></span>
      <div>
        <small>Dólar (USD)</small>
        <PlaceholderText isLoading={isLoading}>
          <strong>R$ {values.usd}</strong>
        </PlaceholderText>
      </div>
      <span></span>
      <div>
        <small>Euro (EUR)</small>
        <PlaceholderText isLoading={isLoading}>
          <strong>R$ {values.eur}</strong>
        </PlaceholderText>
      </div>
      {(values.cad && (
        <>
          <span></span>
          <div>
            <small>Dólar (CAD)</small>
            <PlaceholderText isLoading={isLoading}>
              <strong>R$ {values.cad}</strong>
            </PlaceholderText>
          </div>
        </>
      )) ||
        null}
    </Element>
  );
};

export default ExchangeRates;
